import React from 'react'
import { formatTime } from '@/lib/functions'
import Restricted from '@/components/restricted'

const MemberProducts = ({
  baseUnits,
  mouthPieces,
  onDeAssign,
  productShipmentStatus
}) => {
  return (
    <>
      <h5>Base Units</h5>
      <table className='table table-borderless table-striped mb-5'>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>DMR</th>
            <th>Version</th>
            <th>Assigned Date</th>
            <th>Shipping Status</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {baseUnits.map(device => (
            <tr key={`device-${device.id}`}>
              <td>
                <a href={`/devices/${device.id}`}>{device.serialNumber}</a>
              </td>
              <td>{device.dmr}</td>
              <td>{device.version}</td>
              <td>{formatTime(device.assignedAt)}</td>
              <td>{productShipmentStatus(device)}</td>
              <td>
                <Restricted policy='device::products::de_assign'>
                  <button
                    type='button'
                    className='btn btn-sm text-danger'
                    onClick={() => onDeAssign(device)}
                  >
                    De-assign
                  </button>
                </Restricted>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h5>Mouth Pieces</h5>
      <table className='table table-borderless table-striped mb-5'>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>DMR</th>
            <th>Version</th>
            <th>Assigned Date</th>
            <th>Shipping Status</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {mouthPieces.map(device => (
            <tr key={`device-${device.id}`}>
              <td>
                <a href={`/devices/${device.id}`}>{device.serialNumber}</a>
              </td>
              <td>{device.dmr}</td>
              <td>{device.version}</td>
              <td>{formatTime(device.assignedAt)}</td>
              <td>{productShipmentStatus(device)}</td>
              <td>
                <Restricted policy='device::products::de_assign'>
                  <button
                    type='button'
                    className='btn btn-sm text-danger'
                    onClick={() => onDeAssign(device)}
                  >
                    De-assign
                  </button>
                </Restricted>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default MemberProducts
