import React from 'react'
import { connect } from 'react-redux'
import Restricted from '@/components/restricted'
import MemberProducts from '@/components/member-products'
import MemberShipments from '@/components/member-shipments'
import MemberReturnLabels from '@/components/member-return-labels'
import {
  getClientProducts,
  getClientShipments,
  getClientReplacements,
  deAssignProduct,
  createClientShipment
} from '@/lib/redux/actions'
import {
  selectBaseUnits,
  selectMouthPieces,
  selectShipments
} from '@/lib/redux/selectors'
import { SHIPMENT_STATUSES } from '@/lib/api/schema'

function getShipmentModalId (id) {
  return `shipment-modal-${id}`
}

const ShipmentModalLink = function (props) {
  if (props.disabled) return props.children

  return (
    <a
      href='#'
      data-toggle='modal'
      data-target={`#${getShipmentModalId(props.shipmentID)}`}
    >
      {props.children}
    </a>
  )
}

class DevicesAndShipments extends React.Component {
  componentDidMount () {
    const {
      getClientProducts,
      getClientShipments,
      getClientReplacements,
      clientID
    } = this.props

    getClientProducts(clientID)
    getClientShipments(clientID)
    getClientReplacements(clientID)
  }

  productShipment (shipmentID) {
    if (!shipmentID) return null

    const { shipments } = this.props
    return shipments.filter(function (shipment) {
      return shipment.id === shipmentID
    })[0]
  }

  productShipmentStatus (product) {
    const { replacements } = this.props
    const replacement = replacements[product.id]

    if (replacement) {
      const replacementShipment = this.productShipment(replacement.shipmentID)

      return (
        <ShipmentModalLink shipmentID={replacement.shipmentID}>
          replacement {replacementShipment && replacementShipment.status}
        </ShipmentModalLink>
      )
    }

    const shipment = this.productShipment(product.lastShipment)
    if (!shipment) return null

    let status = shipment.status

    switch (status) {
      case SHIPMENT_STATUSES.SCHEDULED:
        status = `Scheduled to ship by ${shipment.shipBy}`
        break
      case SHIPMENT_STATUSES.SHIPPED:
        status = `Shipped on ${shipment.shippedOn}`
        break
      case SHIPMENT_STATUSES.DELIVERED:
        status = `Delivered on ${shipment.deliveredOn}`
        break
    }

    return (
      <ShipmentModalLink shipmentID={product.lastShipment}>
        {status}
      </ShipmentModalLink>
    )
  }

  deAssignProduct (product) {
    const { deAssignProduct } = this.props

    if (!confirm('Do you want to de-assign this product from the client?'))
      return

    deAssignProduct(product.id)
  }

  createShipment () {
    const { createClientShipment, shipments, clientID } = this.props
    const existingShipment = shipments.filter(function (shipment) {
      return (
        shipment.client.id === clientID &&
        shipment.status === SHIPMENT_STATUSES.CREATED
      )
    })[0]

    if (existingShipment) {
      return alert(
        'There is an active shipment already. Only one active shipment can be created at a time.'
      )
    }

    if (
      !confirm(
        'A new shipment will be created. Are you sure you want to continue?'
      )
    )
      return

    createClientShipment(clientID)
      .then(function (shipment) {
        const modalID = getShipmentModalId(shipment.id)

        window.$(`#${modalID}`).modal('show')
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          alert(error.response.data.error)
        }
      })
  }

  render () {
    const { baseUnits, mouthPieces, shipments, client } = this.props

    return (
      <div className='mb-5'>
        <Restricted policy='device::products'>
          <MemberProducts
            baseUnits={baseUnits}
            mouthPieces={mouthPieces}
            onDeAssign={device => this.deAssignProduct(device)}
            productShipmentStatus={device => this.productShipmentStatus(device)}
          />
        </Restricted>

        <Restricted policy='device::products'>
          <MemberReturnLabels client={client} />
        </Restricted>

        <Restricted policy='device::shipments'>
          <MemberShipments
            shipments={shipments}
            onCreateShipment={() => this.createShipment()}
          />
        </Restricted>
      </div>
    )
  }
}

function mapStateToProps (state, props) {
  const { replacements } = state
  const baseUnits = selectBaseUnits(state).filter(function (product) {
    return product.client === props.clientID
  })
  const mouthPieces = selectMouthPieces(state).filter(function (product) {
    return product.client === props.clientID
  })
  const allShipments = selectShipments(state)
  const shipments = allShipments.filter(function (shipment) {
    return shipment.client.id === props.clientID
  })
  const currentUser = state.currentUser
  const client = state.clients[props.clientID]

  return {
    baseUnits,
    mouthPieces,
    shipments,
    replacements,
    currentUser,
    client
  }
}

const mapDispatchToProps = {
  getClientProducts,
  getClientShipments,
  getClientReplacements,
  deAssignProduct,
  createClientShipment
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesAndShipments)
