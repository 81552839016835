import React, { useState, useEffect } from 'react'
import Icon from '@/components/icon'
import Address from '@/components/address'
import { formatTime } from '@/lib/functions'
import enterpriseApi from '@/lib/api/enterprise'

const MemberReturnLabels = ({ client }) => {
  const [returnLabels, setReturnLabels] = useState([])

  const loadReturnLabels = () => {
    enterpriseApi
      .searchReturnLabels({ member_id: client.id })
      .then(setReturnLabels)
  }

  useEffect(() => {
    loadReturnLabels()
  }, [])

  const unreturnedDevices = returnLabels.filter(
    label => label.status === 'pending'
  )
  const overdueDevices = returnLabels.filter(
    label => label.status === 'pending' && label.deviceReturnDueAt < new Date()
  )

  const getTrackingUrl = returnLabel => {
    switch (returnLabel.shippingCarrierCode) {
      case 'fedex':
        return `https://www.fedex.com/fedextrack/?trknbr=${returnLabel.shippingTrackingNumber}`
      case 'ups':
        return `https://www.ups.com/track?&tracknum=${returnLabel.shippingTrackingNumber}`
    }

    return '#'
  }

  const onCancelReturnLabel = returnLabel => {
    enterpriseApi.cancelReturnLabel(returnLabel.id).then(() => {
      loadReturnLabels()
    })
  }

  const onConfirmReturnLabel = returnLabel => {
    enterpriseApi.confirmReturnLabel(returnLabel.id).then(() => {
      loadReturnLabels()
    })
  }

  const onChargeUnreturnedDevice = returnLabel => {
    enterpriseApi.chargeUnreturnedDevice(returnLabel.id).then(() => {
      loadReturnLabels()
    })
  }

  const onResendShippingLabelEmail = returnLabel => {
    enterpriseApi
      .resendShippingLabelEmail(returnLabel.id)
      .then(() => {
        alert('Shipping label email resent')
        loadReturnLabels()
      })
      .catch(error => {
        alert(`Error resending shipping label email: ${error.message}`)
      })
  }

  const showUnreturnedDeviceChargeAction = returnLabel => {
    if (returnLabel.paymentProcessorStatus === 'failed') {
      return true
    }

    return new Date(returnLabel.deviceReturnDueAt) < new Date()
  }

  if (returnLabels.length === 0) {
    return null
  }

  return (
    <>
      <h5>Return Labels</h5>
      {client.subscriptionStatus !== 'canceled' &&
        unreturnedDevices.length > 0 && (
          <div className='alert alert-danger' role='alert'>
            <Icon icon='exclamation-triangle' /> The member has an active
            subscription and unreturned devices. Applicable charges will be
            applied.
          </div>
        )}

      {overdueDevices.length > 0 && (
        <div className='alert alert-danger' role='alert'>
          <Icon icon='exclamation-triangle' /> The member has an expired
          subscription and overdue devices.
        </div>
      )}

      <table className='table table-borderless table-striped mb-5'>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Status</th>
            <th>Payment Status</th>
            <th>Address</th>
            <th>Tracking Number</th>
            <th>Return Due At Date</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {returnLabels.map(returnLabel => (
            <tr key={`return-label-${returnLabel.id}`}>
              <td>
                <a href={`/devices/${returnLabel.product.id}`}>
                  {returnLabel.product.serialNumber}
                </a>
              </td>
              <td>
                {returnLabel.status !== 'failed' && (
                  <span>{returnLabel.status}</span>
                )}

                {returnLabel.status === 'failed' && (
                  <span className='text-danger'>
                    {returnLabel.status} <Icon icon='exclamation-triangle' />
                  </span>
                )}
              </td>
              <td>
                {returnLabel.paymentProcessorStatus === 'succeeded' && (
                  <a
                    href={`https://dashboard.stripe.com/payments/${returnLabel.paymentProcessorID}`}
                    className='px-1'
                    target='_blank'
                  >
                    {returnLabel.paymentProcessorStatus}{' '}
                    <Icon icon='info-circle' />
                  </a>
                )}

                {returnLabel.paymentProcessorStatus === 'failed' && (
                  <a
                    href={`https://dashboard.stripe.com/payments/${returnLabel.paymentProcessorID}`}
                    data-toggle='tooltip'
                    data-boundary='body'
                    data-container='body'
                    data-placement='right'
                    title={returnLabel.paymentProcessorPayload?.error?.message}
                    className='px-1 text-danger'
                    target='_blank'
                  >
                    {returnLabel.paymentProcessorStatus}{' '}
                    <Icon icon='exclamation-triangle' />
                  </a>
                )}
              </td>
              <td>
                <Address {...returnLabel.address} />
              </td>
              <td>
                <a href={getTrackingUrl(returnLabel)} target='_blank'>
                  {returnLabel.shippingTrackingNumber}
                </a>
              </td>
              <td>{formatTime(returnLabel.deviceReturnDueAt)}</td>
              <td>
                {returnLabel.status === 'pending' && (
                  <div className='dropdown'>
                    <button
                      className='btn btn-link dropdown-toggle'
                      type='button'
                      id={`dropdown-${returnLabel.id}`}
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <Icon icon='ellipsis-h' />
                    </button>
                    <div
                      className='dropdown-menu dropdown-menu-right'
                      aria-labelledby={`dropdown-${returnLabel.id}`}
                    >
                      <button
                        className='dropdown-item'
                        type='button'
                        onClick={() => onConfirmReturnLabel(returnLabel)}
                      >
                        Confirm return
                      </button>

                      {showUnreturnedDeviceChargeAction(returnLabel) && (
                        <button
                          className='dropdown-item'
                          type='button'
                          onClick={() => onChargeUnreturnedDevice(returnLabel)}
                        >
                          Charge unreturned device
                        </button>
                      )}

                      <div className='dropdown-divider' />

                      <button
                        className='dropdown-item'
                        type='button'
                        onClick={() => onCancelReturnLabel(returnLabel)}
                      >
                        Cancel return
                      </button>
                      <button
                        className='dropdown-item'
                        type='button'
                        onClick={() => onResendShippingLabelEmail(returnLabel)}
                      >
                        Resend Shipping Label Email
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default MemberReturnLabels
