import React from 'react'
import { formatTime } from '@/lib/functions'
import Restricted from '@/components/restricted'
import ShipmentModal from './modals/shipment-modal'
import HandoffShipmentModal from './modals/handoff-shipment'
import ShippingAutomationNotes from './shipping-automation-notes'

function getShipmentModalId (id) {
  return `shipment-modal-${id}`
}

const MemberShipments = ({ shipments, onCreateShipment }) => {
  return (
    <>
      <div className='d-flex'>
        <h5 className='mr-auto'>Shipments</h5>

        <Restricted policy='device::shipments::create'>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={onCreateShipment}
          >
            Create Shipment
          </button>
        </Restricted>
      </div>

      <table className='table table-borderless table-striped'>
        <thead>
          <tr>
            <th>Status</th>
            <th>Created on</th>
            <th>Shipped on</th>
            <th>Tracking</th>
            <th>Shipping Automation Status</th>
            <th>Notes</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {shipments.reverse().map(shipment => (
            <tr key={`client-shipment-${shipment.id}`}>
              <td>
                {shipment.status}

                <ShipmentModal
                  {...shipment}
                  modalId={getShipmentModalId(shipment.id)}
                />
              </td>
              <td>{formatTime(shipment.createdAt, 'll')}</td>
              <td>
                {shipment.shippedOn && formatTime(shipment.shippedOn, 'll')}
              </td>
              <td>
                {shipment.trackingURL ? (
                  <a
                    href={shipment.trackingURL}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {shipment.carrier}
                  </a>
                ) : (
                  shipment.carrier
                )}
              </td>
              <td>{shipment.shippingAutomationStatus}</td>
              <td>
                <ShippingAutomationNotes shipment={shipment} />
              </td>
              <td>
                <button
                  type='button'
                  className='btn btn-link btn-sm mr-2'
                  data-toggle='modal'
                  data-target={`#${getShipmentModalId(shipment.id)}`}
                >
                  View
                  <Restricted policy='device::shipments::update'>
                    /Edit
                  </Restricted>
                </button>

                <Restricted policy='device::shipments::update'>
                  <HandoffShipmentModal shipment={shipment}>
                    {({ toggle }) => (
                      <button
                        type='button'
                        className='btn btn-link btn-sm mr-2'
                        onClick={toggle}
                      >
                        Handoff
                      </button>
                    )}
                  </HandoffShipmentModal>
                </Restricted>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default MemberShipments
