import 'symbol-observable'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'
import api from '@/lib/api'
import enterpriseApi from '@/lib/api/enterprise'
import * as schema from '@/lib/api/schema'

/* eslint-disable */
const composeEnhancers =
  typeof window === 'object' &&
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsDenylist: []
      })
    : compose)
/* eslint-enable */

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument({ api, enterpriseApi, schema }))
)

export default createStore(reducer, enhancer)
