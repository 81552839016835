import React, { useEffect, useState } from 'react'
import Icon from '@/components/icon'
import ClientDisplayName from '@/components/client-display-name'
import CheckTable from '@/components/check-table'
import ClientListSelectedActions from '@/components/client-list-selected-actions'
import Pagination from '@/components/pagination'
import Spinner from '@/components/spinner'
import { formatTime } from '@/lib/functions'
import billingApi from '@/lib/api/billing'
import { SUBSCRIPTION_STATUSES } from '@/lib/constants'

const SORT_COLUMNS = {
  name: 'name',
  email: 'email',
  vip: 'vip',
  referral_code: 'referral_code',
  subscription_status: 'subscription_status',
  subscription_canceled_at: 'subscription_canceled_at',
  registered_at: 'registered_at',
  parent_account: 'parent_account'
}

function ClientsList ({
  clients,
  pagination,
  sorting,
  loading,
  onSortingChange,
  onPaginationChange
}) {
  const [notificationMessage, setNotificationMessage] = useState(null)
  const [sortedColumn, setSortedColumn] = useState(sorting.column)
  const [sortedDirection, setSortedDirection] = useState(sorting.direction)
  const [pageSize, setPageSize] = useState(undefined)

  const sortingCaret = column => {
    if (column !== sortedColumn) return null

    const dir = sortedDirection === 'asc' ? 'up' : 'down'

    return <Icon icon={`caret-${dir}`} />
  }

  const handlePageSizeChanged = ev => {
    const value = parseInt(ev.target.value)
    if (!value) return

    setPageSize(value)
  }

  const handlePageSizeKeyPressed = ev => {
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      handlePaginationChange({
        page: 1,
        pageSize
      })
    }
  }

  const handleSortingClick = column => () => {
    let newDirection = 'desc'

    if (column === sortedColumn) {
      newDirection = sortedDirection === 'asc' ? 'desc' : 'asc'
    }

    setSortedColumn(column)
    setSortedDirection(newDirection)
  }

  const handlePaginationChange = pagination => {
    onPaginationChange(pagination)
  }

  const handleActivateMembers = memberIds => {
    return billingApi
      .createSubscriptions(memberIds)
      .then(data => {
        setNotificationMessage({
          message: `${memberIds.length} member enrollment(s) enqueued.`,
          type: 'success'
        })
      })
      .catch(() => {
        setNotificationMessage({
          message: 'Something went wrong.',
          type: 'danger'
        })
      })
  }

  const formatMemberState = client => {
    const { waitlistedAt, memberState } = client

    if (waitlistedAt && memberState === 'registered') {
      return SUBSCRIPTION_STATUSES.registered_waitlisted
    }

    return SUBSCRIPTION_STATUSES[client.memberState]
  }

  useEffect(() => {
    onSortingChange({ column: sortedColumn, direction: sortedDirection })
  }, [sortedColumn, sortedDirection])

  useEffect(() => {
    setPageSize(pagination?.pageSize)
  }, [pagination])

  useEffect(() => {
    setTimeout(() => {
      setNotificationMessage(null)
    }, 5000)
  }, [notificationMessage])

  if (!clients || !clients.length) {
    return (
      <div>
        <div className='card mb-3'>
          <div className='card-body'>
            <strong className='p-5 d-block align-middle text-center'>
              No members found.
            </strong>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='card mb-3'>
        <div className='card-body'>
          {loading && <Spinner className='d-block m-auto p-3' />}
          {!loading && (
            <strong className='mb-2 d-block'>
              Displaying
              <input
                type='text'
                name='pageSize'
                value={pageSize}
                className='mx-2 align-middle text-right'
                style={{ width: '65px' }}
                onChange={handlePageSizeChanged}
                onKeyUp={handlePageSizeKeyPressed}
              />
              members from 1000.
            </strong>
          )}

          {notificationMessage && (
            <div
              className={`alert text-center alert-${notificationMessage.type}`}
              role='alert'
            >
              {notificationMessage?.message}
            </div>
          )}

          <CheckTable
            headers={[
              <th key='1'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.name)}
                >
                  Name {sortingCaret(SORT_COLUMNS.name)}
                </button>
              </th>,
              <th key='2'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.email)}
                >
                  Email {sortingCaret(SORT_COLUMNS.email)}
                </button>
              </th>,
              <th key='3'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.vip)}
                >
                  VIP {sortingCaret(SORT_COLUMNS.vip)}
                </button>
              </th>,
              <th key='4'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.referral_code)}
                >
                  Referral Code {sortingCaret(SORT_COLUMNS.referral_code)}
                </button>
              </th>,
              <th key='5'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.parent_account)}
                >
                  Enterprise Account {sortingCaret(SORT_COLUMNS.parent_account)}
                </button>
              </th>,
              <th key='6'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.subscription_status)}
                >
                  Subscription Status
                  {sortingCaret(SORT_COLUMNS.subscription_status)}
                </button>
              </th>,
              <th key='7'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(SORT_COLUMNS.registered_at)}
                >
                  Registered At
                  {sortingCaret(SORT_COLUMNS.registered_at)}
                </button>
              </th>,
              <th key='8'>
                <button
                  type='button'
                  className='btn text-body'
                  onClick={handleSortingClick(
                    SORT_COLUMNS.subscription_canceled_at
                  )}
                >
                  Canceled At
                  {sortingCaret(SORT_COLUMNS.subscription_canceled_at)}
                </button>
              </th>
            ]}
            selectedHeader={({ selected, clearSelection }) => {
              return (
                <ClientListSelectedActions
                  onActivateMembers={() =>
                    handleActivateMembers(selected).then(() => {
                      clearSelection()
                    })}
                />
              )
            }}
          >
            {({ Row }) => {
              return clients.map(client => (
                <Row key={client.id} id={client.id}>
                  <td className='align-middle'>
                    <ClientDisplayName client={client} link />
                  </td>
                  <td className='align-middle'>{client.email}</td>
                  <td className='align-middle'>{client.vip ? 'Yes' : 'No'}</td>
                  <td className='align-middle'>{client.referralCodeCode}</td>
                  <td className='align-middle'>{client.parentAccountName}</td>
                  <td className='align-middle'>{formatMemberState(client)}</td>
                  <td className='align-middle'>
                    {formatTime(client.createdAt, 'YYYY-MM-DD')}
                  </td>
                  <td className='align-middle'>
                    {formatTime(client.subscriptionCanceledAt, 'YYYY-MM-DD')}
                  </td>
                </Row>
              ))
            }}
          </CheckTable>
        </div>
      </div>

      <Pagination
        totalRecords={pagination.total}
        onPageChange={data => {
          handlePaginationChange({
            page: data.currentPage,
            pageSize: data.pageSize
          })
        }}
        currentPage={pagination.currentPage}
        perPage={pagination.pageSize}
      />
    </div>
  )
}

export default ClientsList
